import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import { marked } from 'marked';

import { contentfulFormType } from 'types';
import Card from 'legacy/components/card';
import ContentfulForm from 'legacy/components/contentfulForm';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import Box from 'legacy/components/box';
import { useHubspotForm } from 'hooks/useHubspotForm';

const StyledCard = styled(Card)(
  css({
    backgroundImage: ['none', null, `url("/images/paper_plane.svg")`],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 15% left 0',
    backgroundSize: '90%',
    pt: 6,
    pb: [5, null, 6],
    px: ['1.5rem', null, 6, null, 8],
  })
);

const Paragraph = styled(Box)(
  css({
    mt: 2,
    '& > p': {
      fontSize: 2,
    },
  })
);

const Disclaimer = styled(Box)(
  css({
    mt: 4,
    '& > p': {
      fontSize: 0,
    },
  })
);

const PostSubmitText = styled(Text)(
  css({
    fontSize: [2, null, null, 3],
    fontWeight: 600,
    color: 'blue',
    textAlign: 'center',
  })
);

const LeadCapture = ({ heading, paragraph, form, disclaimer }) => {
  const [formState, setFormState] = useState('initial');
  const { submitForm: submitHubspotForm } = useHubspotForm(
    form.hubspotFormId,
    form.hubspotSandboxFormId,
    form.salesforceCampaignId,
    form.formType
  );

  const handleSubmit = async (values) => {
    setFormState('loading');

    try {
      await submitHubspotForm(values, 'subscribed_to_newsletter');
      setFormState('success');
    } catch (err) {
      setFormState('error');
    }
  };

  return (
    <StyledCard>
      <Heading variant={['h5', null, 'h4', 'h3']} fontWeight={700} textAlign="center">
        {heading}
      </Heading>
      {!!paragraph?.length && (
        <Paragraph dangerouslySetInnerHTML={{ __html: marked(paragraph) }} />
      )}
      <Box maxWidth={304} mt={4} mx="auto">
        {(formState === 'initial' || formState === 'loading') && (
          <ContentfulForm
            inputs={form.inputsCollection?.items}
            submitButtonText={form.submitButtonText}
            onSubmit={handleSubmit}
            analyticsData={{}}
            disclosures={form.disclosures}
            isLoading={formState === 'loading'}
          />
        )}
        {formState === 'success' && (
          <PostSubmitText>Thanks for subscribing!</PostSubmitText>
        )}
        {formState === 'error' && (
          <PostSubmitText>An error occurred. Please try again later!</PostSubmitText>
        )}
      </Box>
      {!!disclaimer?.length && (
        <Disclaimer dangerouslySetInnerHTML={{ __html: marked(disclaimer) }} />
      )}
    </StyledCard>
  );
};

LeadCapture.propTypes = {
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  form: contentfulFormType.isRequired,
  disclaimer: PropTypes.string.isRequired,
};

LeadCapture.defaultProps = {
  paragraph: '',
};

export default LeadCapture;
