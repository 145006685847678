import styled, { keyframes } from 'styled-components';
import { space } from 'styled-system';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 70px;

  ${space};
`;

const Bounce = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: ${({ theme }) => theme.radii.circle}px;
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`;

const Bounce1 = styled(Bounce)`
  animation-delay: -0.32s;
`;

const Bounce2 = styled(Bounce)`
  margin: 0 9px;
  animation-delay: -0.16s;
`;

const Loading = (props) => (
  <StyledLoading {...props} data-testid="Loading">
    <Bounce />
    <Bounce1 />
    <Bounce2 />
  </StyledLoading>
);

export default Loading;
