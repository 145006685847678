import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { ChevronRight } from 'react-feather';

import Box from 'legacy/components/box';
import Flex from 'legacy/components/flex';
import Section from 'legacy/components/section';
import Link from 'legacy/components/link';

const StyledBreadcrumbs = styled(Box)(
  css({
    width: '100%',
    height: 36,
    bg: 'gray100',
  })
);

const NavItems = styled(Flex)(
  css({
    ml: -2,
    overflowX: ['scroll', 'auto'],
  })
);

const NavItem = styled(Flex)(
  css({
    flexShrink: 0,
  })
);

const NavLink = styled(Link)(
  css({
    my: 2,
    ml: 2,
    fontSize: 0,
    fontWeight: 400,
    lineHeight: '20px',
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  })
);

const Breadcrumbs = ({ category, subcategory, contentType }) => {
  const theme = useTheme();

  const items = [
    {
      label: 'Learn',
      url: '/learn/',
    },
    {
      label: category.title,
      url: `/learn/${category.slug}/`,
    },
    {
      label: subcategory.title,
      url: `/learn/${category.slug}/${subcategory.slug}/articles/`,
    },
    {
      label: contentType,
      url: `/learn/${category.slug}/${subcategory.slug}/${contentType.toLowerCase()}/`,
    },
  ];

  return (
    <StyledBreadcrumbs>
      <Section.Inner>
        <NavItems as="nav">
          {items.map((item, index) => (
            <NavItem key={item.label}>
              {index !== 0 && (
                <Flex justifyContent="center" alignItems="center" ml={2}>
                  <ChevronRight size={20} color={theme.colors.blue} />
                </Flex>
              )}
              <NavLink href={item.url}>{item.label}</NavLink>
            </NavItem>
          ))}
        </NavItems>
      </Section.Inner>
    </StyledBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  subcategory: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  contentType: PropTypes.oneOf(['Articles', 'Calculators', 'Guides', 'Videos'])
    .isRequired,
};

export default Breadcrumbs;
