import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';

const StyledLabel = styled('label')(
  css({
    ml: 1,
    fontSize: 1,
    fontWeight: 600,
    color: 'text',
  })
);

const RedText = styled('span')(
  css({
    color: 'red',
  })
);

const Label = ({ inputId, label, required, ...props }) => (
  <StyledLabel htmlFor={inputId} {...props}>
    {label}&nbsp;{required && <RedText>*</RedText>}
  </StyledLabel>
);

Label.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Label.defaultProps = {
  required: false,
};

export default Label;
