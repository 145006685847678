import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import css from '@styled-system/css';

const StyledInputGroup = styled('div')(
  css({
    position: 'relative',
    cursor: 'pointer',
    '> select': {
      cursor: 'pointer',
    },
    pointerEvents: 'none',
  })
);

const IconWrapper = styled('div')(
  css({
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    width: '2rem',
    height: '100%',
  })
);

const InputGroup = ({ Icon, children }) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledInputGroup>
      {children}
      <IconWrapper>
        <Icon color={theme.colors.gray80} />
      </IconWrapper>
    </StyledInputGroup>
  );
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

export default InputGroup;
