import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useField } from 'formik';
import { IMaskInput } from 'react-imask';

import { track } from 'utils/analytics';
import Label from 'legacy/components/label';
import InputError from 'legacy/components/inputError';
import FormGroup from 'legacy/components/formGroup';
import { baseInputStyles } from 'legacy/components/input';

const Input = styled(IMaskInput)(({ hasError, $hasError }) =>
  css({
    ...baseInputStyles(hasError, $hasError),
  })
);

const PhoneInput = ({
  label,
  name,
  inputId,
  isFullWidth,
  required,
  analyticsData,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  const handleBlur = (e) => {
    field.onBlur(e);

    track('form_interaction', {
      form: {
        type: 'tel',
        name,
        id: inputId,
        input: meta.value,
      },
      ...analyticsData,
    });
  };

  return (
    <FormGroup isFullWidth={isFullWidth}>
      <Label label={label} inputId={inputId} required={required} />
      <Input
        mask="(000) 000-0000"
        id={inputId}
        name={name}
        {...field}
        {...props}
        onBlur={handleBlur}
        $hasError={meta.touched && meta.error}
      />
      {meta.touched && meta.error && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  analyticsData: PropTypes.object,
};

PhoneInput.defaultProps = {
  required: false,
  isFullWidth: false,
  analyticsData: {},
};

export default PhoneInput;
