import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fadeIn } from '../../utils/animations';

const StyledInputGroup = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  animation: ${fadeIn} 0.7s ease;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: ${({ isFullWidth }) => (isFullWidth ? `100%` : `47.5%`)};
    width: ${({ isFullWidth }) => (isFullWidth ? `100%` : `47.5%`)};
  }
`;

const InputGroup = ({ children, ...props }) => (
  <StyledInputGroup {...props}>{children}</StyledInputGroup>
);

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  isFullWidth: PropTypes.bool,
};

InputGroup.defaultProps = {
  isFullWidth: false,
};

export default InputGroup;
