import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledError = styled.p`
  margin-top: 0.25rem;
  padding-left: 0.25rem;
  font-size: 0.8125rem;
  color: #e32b33;
`;

const InputError = ({ children }) => <StyledError>{children}</StyledError>;

InputError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputError;
