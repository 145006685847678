import styled, { ThemeProvider } from 'styled-components';
import css from '@styled-system/css';

import Grid from 'humanity/primitives/grid';
import { contentfulLearningCenterArticleType } from 'types';
import Layout from 'layouts';
import rollbar from 'utils/rollbar';
import {
  getAllLearningCenterArticleSlugs,
  getLearningCenterArticleBySlug,
} from 'lib/contentful';
import { Head } from 'humanity/components/head';
import Article from 'legacy/components/article';
import Section from 'legacy/components/section';
import LeadCapture from 'legacy/components/leadCapture';
import Breadcrumbs from 'legacy/components/breadcrumbs';
import { LcResourcePreview } from 'humanity/components/lcResourcePreview';
import Box from 'humanity/primitives/box';
import { theme } from 'utils/theme.v2';

const Anchor = styled('span')(
  css({
    display: 'block',
    position: 'relative',
    top: [-64, null, null, -80],
    visibility: 'hidden',
  })
);

const StyledRelatedArticles = styled('div')(
  css({
    marginLeft: [0, null, null, '27.5%'],
    px: ['1.5rem', null, null, null],
    width: ['100%', null, null, 'calc(1264px - 27.5%)'],
  })
);
const StyledGrid = styled(Grid)(
  css({
    gridTemplateColumns: ['1fr', null, null, 'repeat(3, 1fr)'],
    gridRowGap: '40px',
  })
);

const StyledText = styled('div')(
  css({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    mb: 3,
  })
);

const LearningCenterArticle = ({ article }) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    author: {
      '@type': 'Person',
      name: article.author?.name,
    },
    datePublished: article.publishDate,
    dateModified: article.lastReviewedDate,
    description: article.seoMetadata?.description,
    headline: article.title,
    image: article.image?.url,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.NEXT_PUBLIC_HI_BASE_URL}/learn/articles/${article.slug}/`,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Human Interest',
      logo: {
        '@type': 'ImageObject',
        url: 'https://images.ctfassets.net/tj9jxg7kaxby/5pfZ2fwcihpJt8VrjB8vAj/99f9d1541caed73f63c76d3df4af8a24/logo_hi.jpg',
        width: 160,
        height: 60,
      },
    },
  };

  const category = article.categoriesCollection?.items?.[0];
  const subcategory = article.subcategoriesCollection?.items?.[0];

  const resources = article.relatedResources?.resourcesCollection?.items;

  return (
    <Layout
      type="learningCenter"
      layout={article.layout}
      notes={article.notes?.itemsCollection?.items}
      disclosures={article.disclosures?.itemsCollection?.items}
    >
      <Head
        title={article.seoMetadata?.title}
        description={article.seoMetadata?.description}
        image={article.seoMetadata?.image?.url}
        noIndex={article.seoMetadata?.noIndex}
        url={
          article.seoMetadata?.canonicalUrl?.length
            ? `${process.env.NEXT_PUBLIC_HI_BASE_URL}${article.seoMetadata?.canonicalUrl}`
            : `${process.env.NEXT_PUBLIC_HI_BASE_URL}/learn/articles/${article.slug}/`
        }
      >
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>
      <Breadcrumbs category={category} subcategory={subcategory} contentType="Articles" />
      <Article type="learningCenter" article={article} />
      {/* This page uses the old theme so need to provide the new one to LcResourcePreview to look correct  */}
      <ThemeProvider theme={theme}>
        <Box mt="0" pt="0" mb="7" color="blue100">
          <StyledRelatedArticles>
            <StyledText>Related Articles</StyledText>
            <StyledGrid>
              {resources?.map((resource) => (
                <LcResourcePreview key={resource.sys.id} resource={resource} />
              ))}
            </StyledGrid>
          </StyledRelatedArticles>
        </Box>
      </ThemeProvider>
      {article.leadCapture && (
        <Section mt={[-6, null, null, -7]}>
          <Section.Inner>
            <Anchor id="subscribe" />
            <LeadCapture
              heading={article.leadCapture?.heading}
              paragraph={article.leadCapture?.paragraph}
              form={article.leadCapture?.form}
              disclaimer={article.leadCapture?.disclaimer}
            />
          </Section.Inner>
        </Section>
      )}
    </Layout>
  );
};

LearningCenterArticle.propTypes = {
  article: contentfulLearningCenterArticleType.isRequired,
};

export const getStaticProps = async ({ params, preview = false }) => {
  try {
    const article = await getLearningCenterArticleBySlug(params.slug, preview);

    if (!article) return { notFound: true };

    return { props: { article }, revalidate: 900 };
  } catch (err) {
    rollbar.error(
      `Error fetching Learning Center article with slug ${params.slug}`,
      err,
      err.response?.data
    );
    throw new Error(`Error fetching Learning Center article with slug ${params.slug}`, {
      cause: err,
    });
  }
};

export const getStaticPaths = async () => {
  const articles = await getAllLearningCenterArticleSlugs();

  const paths = articles.map((article) => ({
    params: { slug: article.slug },
  }));

  return { paths, fallback: 'blocking' };
};

export default LearningCenterArticle;
