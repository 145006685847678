import styled from 'styled-components';
import css, { SystemStyleObject } from '@styled-system/css';

export const baseInputStyles = (
  hasError: boolean,
  $hasError: boolean
): SystemStyleObject => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: hasError || $hasError ? 'red' : 'gray70',
  borderRadius: 'default',
  width: '100%',
  height: '3rem',
  p: 2,
  bg: 'white',
  backgroundOrigin: hasError || $hasError ? 'content-box' : 'border-box',
  fontSize: 2,
  fontFamily: 'body',
  color: 'fieldtext',
  appearance: 'none',
  pointerEvents: 'auto',
  '&:focus': {
    outlineColor: hasError || $hasError ? 'red' : 'teal',
  },
});

type Props = {
  hasError: boolean;
  $hasError: boolean;
};

const Input = styled('input')<Props>(({ hasError, $hasError }) =>
  css({
    ...baseInputStyles(hasError, $hasError),
  })
);

export default Input;
