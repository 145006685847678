import PropTypes from 'prop-types';
import { useField } from 'formik';

import { track } from 'utils/analytics';
import Label from 'legacy/components/label';
import Input from 'legacy/components/input';
import InputError from 'legacy/components/inputError';
import FormGroup from 'legacy/components/formGroup';

const TextInput = ({
  label,
  name,
  inputId,
  type,
  isFullWidth,
  required,
  analyticsData,
  ...props
}) => {
  const [field, meta] = useField({ name, type, ...props });

  const handleBlur = (e) => {
    field.onBlur(e);

    track('form_interaction', {
      form: {
        type,
        name,
        id: inputId,
        input: meta.value,
      },
      ...analyticsData,
    });
  };

  return (
    <FormGroup isFullWidth={isFullWidth}>
      <Label label={label} inputId={inputId} required={required} />
      <Input
        name={name}
        id={inputId}
        type={type}
        {...field}
        {...props}
        onBlur={handleBlur}
        $hasError={meta.touched && meta.error}
      />
      {meta.touched && meta.error && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  type: PropTypes.string,
  isFullWidth: PropTypes.bool,
  required: PropTypes.bool,
  analyticsData: PropTypes.object,
};

TextInput.defaultProps = {
  type: 'text',
  isFullWidth: false,
  required: false,
  analyticsData: {},
};

export default TextInput;
