import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { useField } from 'formik';

import { track } from 'utils/analytics';
import InputGroup from 'legacy/components/inputGroup';
import Label from 'legacy/components/label';
import Input from 'legacy/components/input';
import InputError from 'legacy/components/inputError';
import FormGroup from 'legacy/components/formGroup';

const Select = ({
  name,
  inputId,
  label,
  options,
  isFullWidth,
  required,
  analyticsData,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  const handleBlur = (e) => {
    field.onBlur(e);

    track('form_interaction', {
      form: {
        type: 'select',
        name,
        id: inputId,
        input: meta.value,
      },
      ...analyticsData,
    });
  };

  return (
    <FormGroup isFullWidth={isFullWidth}>
      <Label label={label} inputId={inputId} required={required} />
      <InputGroup Icon={ChevronDown}>
        <Input
          as="select"
          name={name}
          id={inputId}
          {...field}
          onBlur={handleBlur}
          $hasError={meta.touched && meta.error}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {option.label}
            </option>
          ))}
        </Input>
      </InputGroup>
      {meta.touched && meta.error && <InputError>{meta.error}</InputError>}
    </FormGroup>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  isFullWidth: PropTypes.bool,
  required: PropTypes.bool,
  analyticsData: PropTypes.object,
};

Select.defaultProps = {
  required: false,
  isFullWidth: false,
  analyticsData: {},
};

export default Select;
