/* eslint-disable react/require-default-props */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { css } from '@styled-system/css';

import { contentfulRichTextType, stepInput } from 'types';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import PhoneInput from 'legacy/components/phoneInput';
import TextInput from 'legacy/components/textInput';
import Select from 'legacy/components/formikSelect';
import Button from 'legacy/components/button';
import Loading from 'legacy/components/loading';
import Box from 'legacy/components/box';
import SimpleRichText from 'legacy/components/humanity-v1/primitives/simpleRichText';
import Link from 'humanity/primitives/link';

const StyledContentfulForm = styled.form(
  css({
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    mt: -2,
    width: '100%',
    textAlign: 'left',
  })
);

const disclosureRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text variant="small">{children}</Text>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} target="_blank">
        {children}
      </Link>
    ),
  },
};

const ContentfulForm = ({
  inputs,
  onSubmit,
  initialValues,
  title,
  subtitle,
  disclosures,
  submitButtonText,
  submitButtonVariant,
  analyticsData,
  isLoading,
}) => {
  const defaultValues =
    typeof initialValues === 'function' ? initialValues(inputs) : initialValues;

  // build an object to pass to Yup as a validation schema for the form. Use regex fields
  // set in Contentful to validate
  const buildValidationSchema = () => {
    const schemaObject = {};

    inputs.forEach((input) => {
      // handle email and phone inputs specially
      if (input.type === 'email') {
        schemaObject[input.fieldName] = yup
          .string()
          .email('Email must be valid')
          .required(`${input.label} is required`);
      } else if (input.type === 'tel') {
        schemaObject[input.fieldName] = yup
          .string()
          .matches(
            /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g,
            'Please enter a valid phone number'
          )
          .required(`${input.label} is required`);
        // anything else just mark as a required string
      } else if (input.fieldName === 'employees') {
        schemaObject[input.fieldName] = yup
          .number()
          .positive('Please enter a number greater than 0')
          .integer('Please enter an integer')
          .required(`${input.label} is required`)
          .typeError('Please enter a number');
      } else if (input.required) {
        schemaObject[input.fieldName] = yup
          .string()
          .required(`${input.label} is required`);
      } else {
        schemaObject[input.fieldName] = yup.string();
      }
    });

    return schemaObject;
  };

  const validationSchema = yup.object(buildValidationSchema());

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <StyledContentfulForm onSubmit={handleSubmit} data-testid="ContentfulForm">
          {title && (
            <Heading as="h3" width={1} mt={2} fontSize={[4, null, 5]}>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Text width={1} mt={2} mb={3}>
              {subtitle}
            </Text>
          )}
          {inputs.map((input) => {
            // if the input has options, map them to a format our select component can use
            let mappedOptions;
            if (input?.optionsCollection?.items?.length) {
              // create an "empty" option to be placed at the front of the array, so that
              // nothing is selected by default
              const emptyOption = [
                {
                  label: '',
                  value: '',
                  disabled: true,
                  hidden: true,
                },
              ];
              // add the actual options to the end of our 1 item "empty" option array and
              // map the contentful fields to an object our Select component will understand
              mappedOptions = emptyOption.concat(
                input.optionsCollection.items.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))
              );
            }

            switch (input?.type) {
              case 'tel':
                return (
                  <PhoneInput
                    label={input?.label}
                    name={input?.fieldName}
                    required={input?.required}
                    inputId={input?.fieldName}
                    key={input.sys?.id}
                    isFullWidth={!input?.isHalfWidth}
                    placeholder={input?.placeholder}
                    analyticsData={analyticsData}
                    autoComplete="tel"
                  />
                );
              case 'select':
                return (
                  <Select
                    label={input?.label}
                    required={input?.required}
                    name={input?.fieldName}
                    inputId={input?.fieldName}
                    key={input.sys?.id}
                    options={mappedOptions}
                    isFullWidth={!input?.isHalfWidth}
                    placeholder={input?.placeholder}
                    analyticsData={analyticsData}
                    autoComplete={input?.autocompleteValue}
                  />
                );
              default:
                return (
                  <TextInput
                    label={input?.label}
                    required={input?.required}
                    name={input?.fieldName}
                    inputId={input?.fieldName}
                    key={input.sys?.id}
                    isFullWidth={!input?.isHalfWidth}
                    placeholder={input?.placeholder}
                    analyticsData={analyticsData}
                    autoComplete={input?.autocompleteValue}
                  />
                );
            }
          })}
          {disclosures && (
            <Box mt={4}>
              <SimpleRichText
                content={disclosures.json}
                renderOptions={disclosureRenderOptions}
              />
            </Box>
          )}
          {!isLoading && (
            <Button
              type="submit"
              variant={submitButtonVariant}
              btnSize="lg"
              width={1}
              disabled={isLoading}
              mt={4}
              data-testid="ContentfulFormSubmit"
            >
              {submitButtonText}
            </Button>
          )}
          {isLoading && <Loading mx="auto" />}
        </StyledContentfulForm>
      )}
    </Formik>
  );
};

ContentfulForm.propTypes = {
  inputs: PropTypes.arrayOf(stepInput).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disclosures: contentfulRichTextType,
  submitButtonVariant: PropTypes.string,
  submitButtonText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  analyticsData: PropTypes.object.isRequired,
};

ContentfulForm.defaultProps = {
  // default all values to empty string if no initial values are passed in
  initialValues: (inputs) =>
    inputs.reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.fieldName]: '',
      }),
      {}
    ),
  title: '',
  subtitle: '',
  disclosures: null,
  submitButtonVariant: 'secondary',
  submitButtonText: 'Get Started',
};

export default ContentfulForm;
